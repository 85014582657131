import React from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './App.css';
import axios from 'axios';

import server_config from './serverconfig';

const node_address = server_config["node_address"];


const OptOutPage = ( { formState, changeFormState } ) => {
 
  //const history = useHistory();

  const submitOptOut = () => {
    const body = {
      "functionid": "subscribersignuporoptout",
      "displayname": formState.displayname,
      "youtubechannelurl": formState.youtubechannelurl,
      "wanttobeinvideo": false
    };
    axios({
      method: 'POST',
      //url: 'https://what.pizza' + ':' + '3003' + '/',
      //url: 'https://what.pizza:3003/',
      url: node_address,
      timeout: 5000,
      data: body,
    }).then((resp) => {
        if (resp.data.success) {
          changeFormState(true, "submitted");
        } else {
          alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        }
      })
      .catch(err => {
        alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        console.log(err);
      });
  }

  return (
    <Grid>
    <Grid.Column align="center">
    <div>
      <header className="App-header" style={{textAlign: "center"}}><h1 style={{fontSize: "28px", paddingBottom: "6px"}}>Next Subscribers Special Video <span style={{color: "#DD0000"}}>Opt Out</span></h1></header>
      { formState.submitted
        ?
        <Segment className={`containerSegment`} color="green" raised><h3>Your opt out has been successfully submitted.</h3></Segment>
        :
        <>
          <Segment className={`containerSegment`} color="red" raised>
            <h2>Fill this out if you <span style={{color: "#DD0000"}}>DO NOT</span> want to be in the video</h2>
            <br />
            <TextField style={{width: "80%"}} className="inputField" type='text' label='Your username' size="small" variant='outlined' value={formState.displayname || ''} onChange={(e) => { changeFormState(e.target.value, "displayname"); } } />
            <br /><br />
            <TextField style={{width: "80%"}} className="inputField" type='text' label='YouTube channel URL' size="small" variant='outlined' value={formState.youtubechannelurl || ''} onChange={(e) => { changeFormState(e.target.value, "youtubechannelurl"); } } />
            <br />
            <br />
            <Button variant="contained" color="primary" size="large"
            onClick={() => {
                if (formState.displayname !== "") {
                  if (formState.youtubechannelurl !== "") {
                    if ( formState.youtubechannelurl.toLowerCase().includes("youtube.") ) {
                      submitOptOut();
                    } else {
                      alert('Please have your YouTube channel URL in either of these formats: https://youtube.com/KhalidSMShahin OR https://youtube.com/user/KhalidSMShahin OR https://www.youtube.com/channel/UCBiizhPDYWDN1JiB7XGIe1w');
                    }
                  } else {
                    alert('If you want to be in the video, please enter in your YouTube channel URL.');
                  }
                } else {
                  alert('If you want to be in the video, please enter in the name you want to be displayed.');
                }
              }
            }
            ><h3>Submit to <span style={{color: "#EE7777"}}>NOT</span> be in the video</h3></Button>
            <br className={`unselectable`}/>
          </Segment>      
            <br className={`unselectable`}/>
        <div className={`bottomSegment`}>
          <Link to="/subscribersignup"><Segment className={`bottomSegment unselectable`} inverted raised>
          <h2>Back</h2>
          </Segment></Link>
        </div>
        </>
      }
      <br className={`unselectable`}/>
      <br className={`unselectable`}/>
    </div>
    </Grid.Column>
  </Grid>
  );
};

  export default OptOutPage;