import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";
import './App.css';
import { threeLetterWords, fourLetterWords, fiveLetterWords, sixLetterWords, sevenLetterWords, eightLetterWords } from './Words';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import { styled } from '@material-ui/styles';
import SearchBar from "material-ui-search-bar";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


const keyboardLayout = {
  'default': [
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    '{enter} Z X C V B N M {bksp}'
  ]
};

const DarkToggleSwitch = styled(Switch)(({ darkModeState }) => ({
  width: 80,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(5px)',
    '&.Mui-checked': {
      color: '#FFFFFF',
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="45" width="45"><text x="17%" y="60%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">Dark</text></svg>')`,
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: darkModeState ? '#0E3A98' : '#DEA314',
    width: 47,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="45" width="45"><text x="13%" y="60%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">Light</text></svg>')`,
    }
  }
}));

const SoundToggleSwitch = styled(Switch)(({ soundModeState }) => ({
  width: 80,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(5px)',
    '&.Mui-checked': {
      color: '#FFFFFF',
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="45" width="45"><text x="0%" y="60%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">Sound</text></svg>')`,
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: soundModeState ? '#0E983A' : '#989898',
    width: 47,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="45" width="45"><text x="13%" y="60%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">Mute</text></svg>')`,
    }
  }
}));

const startOhNo = () => {
  let ohNoAudio = new Audio("/oh_no.mp3");
  ohNoAudio.play();
}

const startDrum = () => {
  let drumAudio = new Audio("/drum.mp3");
  drumAudio.play();
}

const startBamboo = () => {
  let bambooAudio = new Audio("/bamboo.mp3");
  bambooAudio.play();
}

const startPop = () => {
  let popAudio = new Audio("/pop.wav");
  popAudio.play();
}


const startPing = () => {
  let pingAudio = new Audio("/ping.wav");
  pingAudio.play();
}

const startAraAra = () => {
  let araAraAudio = new Audio("/ara_ara.mp3");
  araAraAudio.play();
}

const startFart = () => {
  let fartAudio = new Audio("/quickfart.mp3");
  fartAudio.play();
}

const startLongFart = () => {
  let longFartAudio = new Audio("/longfart.mp3");
  longFartAudio.play();
}

const createBlankRow = (numberOfLetters) => {
  let blankLetters = [];
  for (let y = 0; y < numberOfLetters; y++) {
    blankLetters.push("");
  }
  return blankLetters;
}

const createBlankLetters = (numberOfTries, numberOfLetters) => {
  let blankRows = [];
  if (numberOfTries > 0 && numberOfLetters > 0) {
    for (let x = 0; x < numberOfTries; x++) {
      blankRows.push(createBlankRow(numberOfLetters));      
    }
  }
  return blankRows;
}

const getRandomWord = (wordLength) => {
  if (wordLength === 5) {
    return fiveLetterWords[Math.floor(Math.random()*fiveLetterWords.length)].toLowerCase();
  } else if (wordLength === 3) {
    return threeLetterWords[Math.floor(Math.random()*threeLetterWords.length)].toLowerCase();
  } else if (wordLength === 4) {
    return fourLetterWords[Math.floor(Math.random()*fourLetterWords.length)].toLowerCase();
  } else if (wordLength === 6) {
    return sixLetterWords[Math.floor(Math.random()*sixLetterWords.length)].toLowerCase();
  } else if (wordLength === 7) {
    return sevenLetterWords[Math.floor(Math.random()*sevenLetterWords.length)].toLowerCase();
  } else if (wordLength === 8) {
    return eightLetterWords[Math.floor(Math.random()*eightLetterWords.length)].toLowerCase();
  }
}

const WordChaserPage = () => {
 
  const [darkModeState, setDarkModeState] = useState(false);
  const [soundModeState, setSoundModeState] = useState(false);

  //const [secretWordState, setSecretWordState] = useState("slide");

  const [youWonState, setYouWonState] = useState(false);
  const [youLoseState, setYouLoseState] = useState(false);
  const [numberOfWrongLettersState, setNumberOfWrongLettersState] = useState(0);

  const [correctLettersState, setCorrectLettersState] = useState([]);
  const [existsLettersState, setExistsLettersState] = useState([]);
  const [missingLettersState, setMissingLettersState] = useState([]);

  const [warningMessageState, setWarningMessageState] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const startingNumberOfLetters = 5;
  const [numberOfLettersState, setNumberOfLettersState] = useState(startingNumberOfLetters);
  const [numberOfTriesState, setNumberOfTriesState] = useState(6);

  const [secretWordState, setSecretWordState] = useState(getRandomWord(startingNumberOfLetters));

  const [userWordAttemptsState, setUserWordAttemptsState] = useState(createBlankLetters(numberOfTriesState, numberOfLettersState));
  const [userWordAttemptsStatusState, setUserWordAttemptsStatusState] = useState(createBlankLetters(numberOfTriesState, numberOfLettersState));
  const [wordsGuessedState, setWordsGuessedState] = useState([]);

  const [currentRowIndexState, setCurrentRowIndexState] = useState(0);
  const [currentRowState, setCurrentRowState] = useState(createBlankRow(numberOfLettersState));
  const [currentLetterIndexState, setCurrentLetterIndexState] = useState(0);

  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(null);

  const [numbersDropDownVisibleState, setNumbersDropDownVisibleState] = useState(true);

  //const [userInputNumberOfLettersState, setUserInputNumberOfLettersState] = useState(5);
  //const [userInputNumberOfTriesState, setUserInputNumberOfTriesState] = useState(6);

  const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  const resetGame = (sound) => {
    if (sound) {
      if (soundModeState) {
        if (youWonState) {
          //startAraAra();
          startPop();
        } else {
          startOhNo();
        }
      }
    }
    setSecretWordState(getRandomWord(numberOfLettersState));
    setYouWonState(false);
    setYouLoseState(false);
    setWarningMessageState("");
    setSuccessMessage("");
    setNumberOfWrongLettersState(0);

    setUserWordAttemptsState(createBlankLetters(numberOfTriesState, numberOfLettersState));
    setUserWordAttemptsStatusState(createBlankLetters(numberOfTriesState, numberOfLettersState));
    setWordsGuessedState([]);
    setCorrectLettersState([]);
    setExistsLettersState([]);
    setMissingLettersState([]);
    setCurrentRowIndexState(0);
    setCurrentRowState(createBlankRow(numberOfLettersState));
    setCurrentLetterIndexState(0);
    setSelectedCharacterIndex(null);
  }

  const submitGuess = () => {
    let guess = currentRowState.join("").toLowerCase();
    let lose = false;
    if ((numberOfLettersState === 5 && fiveLetterWords.includes(guess)) || (numberOfLettersState === 3 && threeLetterWords.includes(guess)) || (numberOfLettersState === 4 && fourLetterWords.includes(guess)) || (numberOfLettersState === 6 && sixLetterWords.includes(guess)) || (numberOfLettersState === 7 && sevenLetterWords.includes(guess)) || (numberOfLettersState === 8 && eightLetterWords.includes(guess))) {
      if (wordsGuessedState.indexOf(guess) != -1) {
        setWarningMessageState("You already guessed that word.");
      } else {
        if (guess == secretWordState) {
          setWarningMessageState("");
          setSuccessMessage("You Got It");
          setYouWonState(true);
          if (soundModeState) {
            startPing();
          }
        } else if (currentRowIndexState + 1 >= numberOfTriesState) {
          setWarningMessageState("The word is: " + secretWordState.toUpperCase());
          lose = true;
          setYouLoseState(true);
        }
        let currentStatus = createBlankRow(numberOfLettersState);
        let answerLettersLeftOver = secretWordState.split('');
        let answerLettersLeftOverIndexesToRemove = [];

        let correctLetters = [];
        let existsLetters = [];
        let missingLetters = [];
        
        let wrongLetters = 0;
        for (let letterIndex in guess) {
          let letter = guess[letterIndex];
          if (secretWordState.indexOf(letter) == -1){
            currentStatus[letterIndex] = "missing";
            missingLetters.push(letter);
            wrongLetters += 1;
          }
        }
        setNumberOfWrongLettersState(prevState => { return prevState+wrongLetters; } );
        if (wrongLetters == numberOfLettersState) {
          if (soundModeState) {
            startFart();
          }
        }
        for (let letterIndex in guess) {
          let letter = guess[letterIndex];
          let correctLetter = secretWordState[letterIndex];
          if (letter == correctLetter){
            currentStatus[letterIndex] = "correct";
            answerLettersLeftOverIndexesToRemove.push(letterIndex);
            correctLetters.push(letter);
          } else {
          }
        }
        let newCorrectLetters = correctLettersState;
        let newMissingLetters = missingLettersState;
        let newExistsLetters = existsLettersState;
        for (let correctLetterIndex in correctLetters) {
          let correctLetter = correctLetters[correctLetterIndex];
          if (newCorrectLetters.indexOf(correctLetter) == -1) {
            newCorrectLetters.push(correctLetter);
          }
          if (newExistsLetters.indexOf(correctLetter) != -1) {
            newExistsLetters.splice(newExistsLetters.indexOf(correctLetter), 1)
          }
          /*
          if (newMissingLetters.indexOf(correctLetter) != -1) {
            newMissingLetters.splice(newMissingLetters.indexOf(correctLetter), 1)
          }*/
        }
        setCorrectLettersState(prevState => {
          { return newCorrectLetters; }
        } );
        answerLettersLeftOverIndexesToRemove.sort();
        answerLettersLeftOverIndexesToRemove.reverse();
        for (let letterIndexIndex in answerLettersLeftOverIndexesToRemove) {
          let letterIndex = answerLettersLeftOverIndexesToRemove[letterIndexIndex];
          answerLettersLeftOver.splice(letterIndex, 1);
        }
        for (let letterIndex in guess) {
          if (currentStatus[letterIndex] == "") {
            let letter = guess[letterIndex];
            let answerLetterIndex = answerLettersLeftOver.indexOf(letter);
            if (answerLetterIndex == -1){
              currentStatus[letterIndex] = "missing";
              missingLetters.push(letter);
            } else {
              answerLettersLeftOver.splice(answerLetterIndex, 1);
              currentStatus[letterIndex] = "exists";
              existsLetters.push(letter);
            }
          }
        }
        for (let existsLetterIndex in existsLetters) {
          let existsLetter = existsLetters[existsLetterIndex];
          if (newCorrectLetters.indexOf(existsLetter) == -1) {
            if (newExistsLetters.indexOf(existsLetter) == -1) {
              newExistsLetters.push(existsLetter);
            }
          }
        }
        setExistsLettersState(prevState => {
          { return newExistsLetters; }
        } );
        for (let missingLetterIndex in missingLetters) {
          let missingLetter = missingLetters[missingLetterIndex];
          if (newCorrectLetters.indexOf(missingLetter) == -1) {
            if (newExistsLetters.indexOf(missingLetter) == -1) {
              if (newMissingLetters.indexOf(missingLetter) == -1) {
                newMissingLetters.push(missingLetter);
              }
            }
          }
        }
        setMissingLettersState(prevState => {
          { return newMissingLetters; }
        } );
        setWordsGuessedState(prevState => { prevState.push(guess); { return prevState; } } );
        setUserWordAttemptsStatusState(prevState => { prevState[currentRowIndexState] = currentStatus; { return prevState; } } );
        setUserWordAttemptsState(prevState => { prevState[currentRowIndexState] = currentRowState; { return prevState; } } );
        setCurrentLetterIndexState(0);
        setCurrentRowState(createBlankRow(numberOfLettersState));
        setCurrentRowIndexState(prevState => { if (prevState < numberOfTriesState) { return prevState+1; } } );
      }

    } else {
      setWarningMessageState("Not a Word");
      setSuccessMessage("");
    }
  }

  const letterPress = (eventKey) => {
    setWarningMessageState("");
    if (eventKey) {
      let letter = eventKey.toLowerCase();
      if (letters.indexOf(letter) != -1) {
        if (currentLetterIndexState < numberOfLettersState) {
          setCurrentRowState(prevState => { prevState[currentLetterIndexState] = letter; return prevState; } )
          setCurrentLetterIndexState(prevState => { if (prevState < numberOfLettersState) { return prevState+1; } else { return prevState; } } );
        } else {

        }
      } else if (letter == "backspace") {
        if (selectedCharacterIndex != null) {
          setCurrentRowState(prevState => { prevState[currentLetterIndexState] = ""; return prevState; } )
        } else {
          if (currentLetterIndexState > 0) {
            setCurrentRowState(prevState => { prevState[currentLetterIndexState-1] = ""; return prevState; } )
            setCurrentLetterIndexState(prevState => { if (prevState > 0) { return prevState-1; } else { return prevState; } } );
          }
        }
      } else if (letter == "delete") {
        if (selectedCharacterIndex != null) {
          setCurrentRowState(prevState => { prevState[currentLetterIndexState] = ""; return prevState; } )
        }
      } else if (letter == "enter") {
        if (currentRowState.join("").length == numberOfLettersState) {
          submitGuess();
        } else {
          setWarningMessageState("Not enough letters");
        }
      }
    }
    setSelectedCharacterIndex(null);
  }

  const handleKeyDown = (event) => {
    if (!youLoseState && !youWonState) {
      setSuccessMessage("");
      if (!event.repeat) {
        let eventKey = event.key;
        letterPress(eventKey);
      }
    } else {
      setSelectedCharacterIndex(null);
    }
  }
  //const history = useHistory();

  let colorModeSegment = "lightSegment";
  let segmentColor = "violet";
  let colorModeKeyboard = "hg-theme-lightmode";
  let paperBackgroundColor = "";
  let letterColor = "#000000";
  let correctColor = "green";
  let missingColor = "#999999";
  let existsColor = "orange";
  let previousLetterBorderColor = "2px solid #C0C0C099";
  let currentLetterBorderColor = "2px solid #C0C0C0";
  let laterLetterBorderColor = "2px solid #999999";
  let letterFilledBorderColor = "3px solid #777777";
  if (darkModeState) {
    colorModeSegment = "darkSegment";
    colorModeKeyboard = "hg-theme-darkmode";
    paperBackgroundColor = "#000000";
    letterColor = "#ECECEC";
    correctColor = "#006A00";
    missingColor = "#5A5A5A";
    existsColor = "#AF5A00";
    previousLetterBorderColor = "2px solid #50505099";
    currentLetterBorderColor = "2px solid #505050";
    laterLetterBorderColor = "2px solid #777777";
    letterFilledBorderColor = "3px solid #B0B0B0";
  } else {

  }

  const LetterBox = ({letter, letterIndex, rowIndex, currentRowIndexState, userWordAttemptsStatusState}) => {
    const sizeOfSquare = "41";
    if (letter) {
      letter = letter.toUpperCase();
    }
    let boxStyle = {marginLeft: "4px", outline: previousLetterBorderColor, width: sizeOfSquare+"px", height: sizeOfSquare+"px", lineHeight: (sizeOfSquare*0.96)+"px", textAlign: "center", align: "center", vertricalAlign: "middle", fontSize: "34px", fontWeight: "bold"};
    if (rowIndex == currentRowIndexState) {
      if (letter) {
        boxStyle["outline"] = letterFilledBorderColor;
      } else {
        boxStyle["outline"] = currentLetterBorderColor;
      }
      if (selectedCharacterIndex == letterIndex) {
        boxStyle["outline"] = "3px solid #7777FF";
      }
    } else if (rowIndex < currentRowIndexState) {
      let letterStatus = userWordAttemptsStatusState[rowIndex][letterIndex];
      if (letterStatus === "correct") {
        boxStyle["backgroundColor"] = correctColor;
      } else if (letterStatus === "missing") {
        boxStyle["backgroundColor"] = missingColor;
      } else if (letterStatus === "exists") {
        boxStyle["backgroundColor"] = existsColor;
      }
    } else {
      boxStyle["outline"] = laterLetterBorderColor;
    }
    return <div className="unselectable" style={boxStyle} onClick={() => {
            if (rowIndex == currentRowIndexState) {
              setCurrentLetterIndexState(prevState => { return letterIndex; } );
              setSelectedCharacterIndex(letterIndex);
            }
           }}>
              <span style={{color: letterColor}}>{letter}</span>
           </div>
  }

  useEffect(() => {
    if (soundModeState) {
      if (numberOfWrongLettersState == numberOfLettersState*numberOfTriesState) {
        startLongFart();
      } else {
        startBamboo();
      }
    }
  }, [youLoseState]);

  useEffect(() => {
    resetGame(false);
    setNumbersDropDownVisibleState(true);
  }, [numberOfLettersState]);

  let selectStyle = {};
  let selectItemStyle = {};
  if (darkModeState) {
    selectStyle = {backgroundColor: "#333333", color: "#DDDDDD"};
    selectItemStyle = {backgroundColor: "#777777", color: "#DDDDDD"};
  }

  return (
    <Grid className="typingArea" onKeyDown={handleKeyDown} tabIndex="0" autoFocus style={{height: "100%"}}>
    <Grid.Column align="center" style={{margin: "0px", padding: "0px", height: "100%"}}>
    <div>
      <header className={"App-header", "unselectable"} style={{textAlign: "center"}}><h1 style={{fontSize: "26px", margin: "0px", padding: "0px", height: "30px"}}>Word Chaser</h1></header>
      <Segment className={`containerSegment `+ colorModeSegment} color={segmentColor} style={{margin: "0px", height: "calc(100vh - 30px)"}} raised>
        <div style={{paddingLeft: "10px", top: "0px", position: "absolute"}}>
          <DarkToggleSwitch darkModeState={darkModeState} onChange={() => {setDarkModeState(prevState => { return !prevState; } )} } />
          <br/><div style={{paddingTop: "4px"}} />
          <SoundToggleSwitch  soundModeState={soundModeState} onChange={() => {setSoundModeState(prevState => { return !prevState; } )} } />
        </div>
        <Button style={{position: "absolute", marginTop: "4px", right: "16px", backgroundColor: "#9D0038", color: "#FFFFFF"}} variant="contained" size="small" onMouseUp={() => { resetGame(true); } }><h3>RESET</h3></Button>
        {numbersDropDownVisibleState && <FormControl style={{marginLeft: "8px", width: "140px"}}>
          <InputLabel id="number-of-letters-label" style={selectStyle}>Number of Letters</InputLabel>
          <Select
            labelId="number-of-letters-label"
            id="number-of-letters"
            value={numberOfLettersState}
            label="Number of Letters"
            style={selectStyle}
            onChange={(e, data) => { setNumbersDropDownVisibleState(false); setNumberOfLettersState(e.target.value); } }
          >
            <MenuItem style={selectItemStyle} value={3}>3</MenuItem>
            <MenuItem style={selectItemStyle} value={4}>4</MenuItem>
            <MenuItem style={selectItemStyle} value={5}>5</MenuItem>
            <MenuItem style={selectItemStyle} value={6}>6</MenuItem>
            <MenuItem style={selectItemStyle} value={7}>7</MenuItem>
            <MenuItem style={selectItemStyle} value={8}>8</MenuItem>
          </Select>
        </FormControl>}
        <br/><br/>
        <div style={{height: "28px", fontSize: "22px"}}>
          <div style={{color: "red", fontWeight: "bold"}}>{warningMessageState}</div>
          <div style={{color: "green", fontWeight: "bold"}}>{successMessage}</div>
        </div>
        <table>
          <tbody>
            {userWordAttemptsState.map((wordAttempt, rowIndex) => {
              return <tr key={rowIndex}>
                {wordAttempt.map((letterAttempt, letterIndex) => {
                  let letter = letterAttempt;
                  let currentRow = false;
                  if (rowIndex == currentRowIndexState) {
                    currentRow = true;
                    letter = currentRowState[letterIndex];
                  }
                  return <td key={letterIndex}><LetterBox letter={letter} letterIndex={letterIndex} rowIndex={rowIndex} currentRowIndexState={currentRowIndexState} userWordAttemptsStatusState={userWordAttemptsStatusState} /><div style={{height: "8px"}} /></td>
                })}
              </tr>
            })}
          </tbody>
        </table>
        <div style={{width: "100%", position: "absolute", bottom: "0px", align: "center", textAlign: "center"}}>
          <Keyboard
            theme={"hg-theme-default "+colorModeKeyboard}
            keyboardRef={() => {}}
            layout={keyboardLayout}
            display={{
              '{bksp}': '⌫',
              '{enter}': 'ENTER',
            }}
            buttonTheme={[
              {
                class: "hg-correct",
                buttons: correctLettersState.join(" ").toUpperCase()
              },
              {
                class: "hg-exists",
                buttons: existsLettersState.join(" ").toUpperCase()
              },
              {
                class: "hg-missing",
                buttons: missingLettersState.join(" ").toUpperCase()
              },
            ]}
            layoutName="default"
            onKeyPress={(event, data) => { let eventKey = event.replace('{enter}', 'enter').replace('{bksp}', 'backspace'); letterPress(eventKey); }}
          />
        </div>
      </Segment>
        {/*}
        <br className={`unselectable`}/>
      <div className={`bottomSegment`}>
        <Link to="/"><Segment className={`bottomSegment unselectable`} inverted raised>
        <h2>Back</h2>
        </Segment></Link>
      </div>
      <br className={`unselectable`}/>
      <br className={`unselectable`}/>
      */}
    </div>
    </Grid.Column>
  </Grid>
  );
};

  export default WordChaserPage;