import React from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './App.css';


const HomePage = ( { formState, changeFormState } ) => {
 
  //const history = useHistory();
  
  return (
    <Grid>
    <Grid.Column align="center">
    <div>
      <header className="App-header" style={{textAlign: "center"}}><h1 style={{fontSize: "36px", paddingBottom: "0px"}}>What.Pizza</h1></header>
        <>
          <Segment className={`containerSegment`} color="violet" raised>
            <h3>Just a site where I put some of my stuff.</h3>
          </Segment>

          <Segment className={`containerSegment`} color='green' raised> 
            <h1>Projects</h1>
            <br />
            <Link to="/wordchaser"><Button variant="contained" color="primary" size="large" onClick={() => { }}>
              <h3>Word Chaser</h3></Button>
            </Link>
            <br/><br/>
            <Link to="/subscribersignup"><Button variant="contained" color="primary" size="large" onClick={() => { }}>
              <h3>YouTube Subscriber Sign Up</h3></Button>
            </Link>
          </Segment>
          <br className={`unselectable`}/>
          <div className={`bottomSegment`}>
            {/*<Link to="/"><Segment className={`bottomSegment unselectable`} inverted raised onClick={() => { }}>
              <h2>Back</h2>
            </Segment></Link>*/}
          </div>
        </>
      </div>
    </Grid.Column>
  </Grid>
  );
};

  export default HomePage;