import React from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './App.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import server_config from './serverconfig';

const node_address = server_config["node_address"];

const SubscriberSignUpPage = ( { formState, changeFormState } ) => {
 
  //const history = useHistory();

  const submitSignUp = () => {
    const body = {
      "functionid": "subscribersignuporoptout",
      "displayname": formState.displayname,
      "youtubechannelurl": formState.youtubechannelurl,
      "wanttobeinvideo": true,
      "captchatoken": formState.captchatoken
    };
    axios({
      method: 'POST',
      //url: 'https://what.pizza' + ':' + '3003' + '/',
      //url: 'https://what.pizza:3003/',
      url: node_address,
      timeout: 5000,
      data: body,
    }).then((resp) => {
        if (resp.data.success) {
          changeFormState(true, "submitted");
        } else {
          alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        }
      })
      .catch(err => {
        alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        console.log(err);
      });
  }

  const onCaptchaChange = (value) => {
    changeFormState(value, "captchatoken");
  }
  
  return (
    <Grid>
    <Grid.Column align="center">
    <div>
      <header className="App-header" style={{textAlign: "center"}}><h2 style={{fontSize: "28px", paddingBottom: "6px"}}>Next Subscribers Special Video <span style={{color: "#008440"}}>Sign Up</span></h2></header>
      { formState.submitted
        ?
        <Segment className={`containerSegment`} color="green" raised><h3>Your signup has been successfully submitted!</h3></Segment>
        :
        <>
          <Segment className={`containerSegment`} color="violet" raised>
            <h3>The next subscribers video will feature my subscribers' avatars and usernames that I can manage to gather, unless you opt out of it.</h3>
          </Segment>   

          <Segment className={`containerSegment`} color='green' raised> 
            <h2>Fill this out if you want to be in the subscribers video</h2>
            <br />
            <TextField style={{width: "80%"}} className="inputField" type='text' label='Name to be displayed in video' size="small" variant='outlined' value={formState.displayname || ''} onChange={(e) => { changeFormState(e.target.value, "displayname"); } } />
            <br /><br />
            <TextField style={{width: "80%"}} className="inputField" type='text' label='YouTube channel URL' size="small" variant='outlined' value={formState.youtubechannelurl || ''} onChange={(e) => { changeFormState(e.target.value, "youtubechannelurl"); } } />
            <br />
            <FormControlLabel style={{paddingTop: "12px", paddingBottom: "12px"}}
            control={<Checkbox color="primary" checked={formState.wanttobeinvideo} onChange={ (e) => { changeFormState(!formState.wanttobeinvideo, "wanttobeinvideo") } } name="consent" />}
            label={<Box component="div" fontSize={22} className="unselectable"> I want to be in the video </Box> }
                />
            <br />
            <ReCAPTCHA sitekey="6LercmUaAAAAAEb0oU9DAWCAv5auYucNpoOH0PeC" onChange={onCaptchaChange}/>
            <br />
            <Button variant="contained" color="primary" size="large"
            onClick={() => {
              if (formState.wanttobeinvideo) {
                if (formState.displayname !== "") {
                  if (formState.youtubechannelurl !== "") {
                    if ( formState.youtubechannelurl.toLowerCase().includes("youtube.") ) {
                      if (formState.captchatoken !== "" ) {
                        submitSignUp();
                      } else {
                        alert('Please complete the captcha by checking the box and following the instructions. If there\'s an issue with this please email KhalidShahinIP@gmail.com');
                      }
                    } else {
                      alert('Please have your YouTube channel URL in either of these formats: https://youtube.com/KhalidSMShahin OR https://youtube.com/@KhalidSMShahin OR https://youtube.com/user/KhalidSMShahin OR https://www.youtube.com/c/KhalidSMShahin/ OR https://www.youtube.com/channel/UCBiizhPDYWDN1JiB7XGIe1w');
                    }
                  } else {
                    alert('If you want to be in the video, please enter in your YouTube channel URL.');
                  }
                } else {
                  alert('If you want to be in the video, please enter in the name you want to be displayed.');
                }
              } else {
                alert('If you want to be in the video, please check the box. If you do not want to be in the video, click the button at the bottom of the page.');
              }
            }}
            ><h3>Submit</h3></Button>
          </Segment>
          <br className={`unselectable`}/>
          <div className={`bottomSegment`}>
          <Link to="opt-out"><Segment className={`bottomSegment unselectable`} inverted raised onClick={() => {changeFormState(false, "wanttobeinvideo");}}>
            <h2>If you <span style={{color: "#EE0000"}}>do not</span> want to be in the video click here.</h2>
          </Segment></Link>
        </div>
        </>
      }
      </div>
    </Grid.Column>
  </Grid>
  );
};

  export default SubscriberSignUpPage;